// polyfill needs to be imported before anything else
import "$ui-components/polyfill";

import {
	PUBLIC_APPSYNC_URL,
	PUBLIC_COGNITO_CLIENT_ID,
	PUBLIC_COGNITO_HOSTED_UI,
	PUBLIC_COGNITO_POOL_ID,
	PUBLIC_STAGE,
	PUBLIC_VERSION
} from "$env/static/public";
import { HewSync } from "$hewsync/HewSync";
import { getOrOverrideToken, registerErrorHandlers } from "$ui-components/utils";
import * as Sentry from "@sentry/svelte";
import type { HandleClientError } from "@sveltejs/kit";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";

Sentry.init({
	attachStacktrace: true,
	dsn: "https://2a99dfc9b64d41ac86461994e6d71955@o776661.ingest.sentry.io/6710638",
	enabled: import.meta.env.PROD,
	environment: PUBLIC_STAGE,
	integrations: [],
	release: PUBLIC_VERSION,
	tracesSampleRate: 0
});

registerErrorHandlers();

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: PUBLIC_COGNITO_CLIENT_ID,
			userPoolId: PUBLIC_COGNITO_POOL_ID,
			loginWith: {
				oauth: {
					domain: PUBLIC_COGNITO_HOSTED_UI.replace("https://", ""),
					redirectSignIn: [window.location.origin],
					redirectSignOut: [window.location.origin],
					responseType: "code",
					scopes: ["email", "openid", "phone", "profile"]
				},
				username: false,
				phone: false,
				email: false
			}
		}
	}
});

const getSession = async () => {
	try {
		const { tokens } = await fetchAuthSession({ forceRefresh: true });
		console.debug("[getSession] Tokens:", tokens);
		if (tokens) {
			HewSync.onAuth(getOrOverrideToken(tokens.accessToken.toString()), PUBLIC_APPSYNC_URL);
		}
	} catch (err) {
		console.error("[getSession]: Unable to get Auth User:", err);
	}
};

await getSession();

export const handleError: HandleClientError = (({ error, event }) => {
	Sentry.captureException(error, { contexts: { sveltekit: { event } } });
	return { message: error.message };
}) satisfies HandleClientError;
